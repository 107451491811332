import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterContentChecked, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  SiteModel,
  MeetingOccurrenceModel,
  MeetingSubjectTypes,
  ProcessResponse,
  SiteAttributeModel
} from 'src/app/api/models';
import { ICON_CONFIG } from '@core/models/icons.model';
import { InquiriesService } from '../../../modules/inquiries/services/inquiries.service';
import { BookingFilterComponent } from '../booking-filter/booking-filter.component';
import { ClientService } from '@api/services';
import { ModalService, ERROR_DIALOG } from '@shared/services/modal.service';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { BaseComponent } from '@shared/components/base/base.component';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { AppointmentsDataService } from '@modules/client/services/appointments-data.service';
import {
  cannotCreateClientCaseForExistingEOActive,
  checkDuplicateClientListPopupMessageOnInquiry,
  ClientListPopupComponent,
  DialogResultOptions,
} from '../client-list-popup/client-list-popup.component';
import { TranslocoService } from '@ngneat/transloco';
import { SchedulingDataService } from '@modules/scheduling/services/scheduling-data.service';

const filterIcon = ICON_CONFIG.filter;
const closeDrawerIcon = ICON_CONFIG.filterClose;

export const MY_FORMATS = {
  parse: {
    dateInput: { day: 'numeric', month: 'short', year: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@Component({
  selector: 'app-book-cat-appointment',
  template: `<cf2-header-row [drawerIcons]="filterIcons" [isLoading]="!ready || loadingTimeslots">
    <header-title>
      <a href="javascript: void(0)" (click)="navigateToDetail()">{{ pageTitle }}</a>
    </header-title>

    <!-- Filters -->
    <drawer-content>
      <app-booking-filter
        *ngIf="!isClientModule()"
        (siteChange)="siteChange($event)"
        [parentSiteKey]="parentSiteKey"
        #bookingFilter
      ></app-booking-filter>
    </drawer-content>

    <page-content>
      <ng-container *transloco="let t">  
        <!-- Calendar -->
        <mat-card class="edit-container">
          <mat-card-content>
            <div class="book-container">
              <div class="booking-header">
                <div class="cardRow">
                  <cf2-select-field-top
                    class="appointmentType"
                    [ctrl]="form.controls.appointmentType"
                    [label]="'AppointmentType' | transloco"
                    [fullWidth]="false"
                    [disabled]="true"
                    codeTable="MeetingSubjectType"
                    #inquiryStatus
                  ></cf2-select-field-top>

                  <ng-container *ngIf="site">
                    <div class="flex-col">
                      <div class="site-header">
                        <h3>{{ this.site.organizationName }}</h3>
                        <span
                          >{{ this.site.siteAddresses[0].siteAddressLine1 }}, {{ this.site.siteAddresses[0].city }}</span
                        >
                      </div>
                      <div class="site-attributes">
                        <span *ngFor="let attr of siteAttributes; let i = index">
                          {{ attr.attributeDescription }} <span *ngIf="i === siteAttributes.length - 2">,</span>
                        </span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="calendar-container mb-2">
                <div class="calendar-container2 mb-2">
                  <mat-card class="booking-calendar mb-2">
                    <mat-calendar
                      #calendar
                      [dateClass]="dateClass()"
                      [selected]="selectedDate"
                      [maxDate]="maxDate"
                      [minDate]="minDate"
                      startView="month"
                      (selectedChange)="onSelect($event)"
                    >
                    </mat-calendar>
                  </mat-card>

                  <!-- <ng-container *ngIf="inquiriesService.getSiteDesignations() | async as siteDesignations">
                    <div class="site-designations">
                      {{ siteDesignations | json }}
                    </div>
                  </ng-container> -->

                  <div class="appointments" #appointments>
                    <!-- Select an appointment -->
                    <ng-container *ngIf="parentSiteKey >= 0 && availableAppointments.length > 0 && !loadingTimeslots">
                      <ng-container *ngIf="!selectedAppt; else confirmAppt">
                        <h3 class="appointment-list-header" id="nextAppt">{{ t('NextAvailableAppointments') }}</h3>
                        <div class="appointment-list mb-2">
                          <ul mdcList *ngIf="availableAppointments && availableAppointments.length > 0">
                            <ng-container *ngFor="let opt of availableAppointments" class="mat-elevation-z2">
                              <li mdcListItem (click)="selectedAppointment(opt)">
                                <span>
                                  {{ opt.meetingDate | date: 'dd-MMM-yyyy' }}
                                </span>
                                <span>
                                  {{ inquiriesService.parseTimeFormatted(opt.startISOFormatted) }}
                                </span>
                                <span class="appointment-list-details">
                                  {{ opt.employeeAttendees[0].displayName }} 
                                </span>
                                |
                                <span class="appointment-list-details">
                                   {{ opt.meetingDescription }} 
                                </span>
                                |
                                <span class="appointment-list-details">
                                   {{ opt.employeeAttendees[0].languageDescription  }} 
                                </span>
                              </li>
                              <li mdcListDivider></li>
                            </ng-container>
                          </ul>
                        </div>
                      </ng-container>
                    </ng-container>

                    <!-- No appointments available -->
                    <ng-container *ngIf="parentSiteKey >= 0 && availableAppointments.length === 0 && !loadingTimeslots">
                      <h3 class="appointment-list-header" id="nextAppt">{{ t('NextAvailableAppointments') }}</h3>
                      <div class="appointment-list mb-2">{{t("AppointmentBooking.Message.Info.NoAppointments")}}</div>
                      <div class="spacer"></div>
                    </ng-container>

                    <!-- No site selected -->
                    <ng-container *ngIf="parentSiteKey <= 0 && !loadingTimeslots">
                      <h3 class="appointment-list-header" id="nextAppt">{{ t('NextAvailableAppointments') }}</h3>
                      <div class="appointment-list mb-2">{{ t('PleaseSelectASite') }}.</div>
                      <div class="spacer"></div>
                    </ng-container>

                    <!-- Appointment Confirmation -->
                    <ng-template #confirmAppt>
                      <ng-container *ngIf="!isSubmitted; else submitted">
                        <div class="confirm-container">
                          <h3 class="appointment-list-header" id="nextAppt">{{ t('ConfirmBooking') }}</h3>
                          <p>
                            {{ selectedAppt.meetingDate | date: 'fullDate' }},
                            {{ inquiriesService.parseTimeFormatted(selectedAppt.startISOFormatted) }}
                          </p>

                          <div class="confirm-location">
                            <span
                              ><b>{{ this.site.organizationName }}</b></span
                            >
                            <span
                              ><em
                                >{{ site.siteAddresses[0].siteAddressLine1 }}, {{ site.siteAddresses[0].city }}</em
                              ></span
                            >
                          </div>
                        </div>
                        <div class="submit-button">
                          <cf2-button
                          [buttonText]="'ConfirmBooking' | transloco"
                            isPrimary="true"
                            (callback)="submitIntakeMeeting()"
                          ></cf2-button>
                        </div>
                        <div class="submit-button">
                          <cf2-button [buttonText]="'Cancel' | transloco" (callback)="cancel()"></cf2-button>
                        </div>
                        <br />
                      </ng-container>
                    </ng-template>

                    <!-- Appointment Booked -->
                    <ng-template #submitted>
                      <div class="confirm-container">
                        <h3 class="appointment-list-header" id="nextAppt">{{ t('Success') }}!</h3>
                        <p>
                          {{ selectedAppt.meetingDate | date: 'fullDate' }},
                          {{ inquiriesService.parseTimeFormatted(selectedAppt.startISOFormatted) }}
                        </p>
                        <div class="confirm-location">
                          <span
                            ><b>{{ this.site.organizationName }}</b></span
                          >
                          <span
                            ><em>{{ site.siteAddresses[0].siteAddressLine1 }}, {{ site.siteAddresses[0].city }}</em></span
                          >
                        </div>
                        <div class="submit-button">
                          <cf2-button
                            [buttonText]="isClientModule() ? 'Return to client appointments' : 'Return to Inquiry List'"
                            isPrimary="true"
                            (callback)="close()"
                          ></cf2-button>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </page-content>
  </cf2-header-row>`,
	encapsulation: ViewEncapsulation.None,
  styleUrls: ['./book-cat-appointment.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS, deps: [MAT_DATE_LOCALE] },
  ],
})
export class BookCatAppointmentComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('bookingFilter') bookingFilter: BookingFilterComponent;
  @ViewChild('calendar') calendar: MatCalendar<Date>;

  public pageTitle = '';
  public serviceTitle = '';

  public form: UntypedFormGroup;
  public appointmentType = MeetingSubjectTypes.TimeslotInt;
  public parentSiteKey = -1;
  public site: SiteModel;
  public availableAppointments: MeetingOccurrenceModel[] = [];
  public availableAppointmentDates: string[] = undefined;

  public ready = false;
  public loadingTimeslots = true;

  public filterIcons = [closeDrawerIcon, filterIcon];
  public unlockRecord = true;

  public selectedDate = undefined;
  public minDate = new Date();
  public maxDate = new Date(this.minDate);

  public selectedAppt: MeetingOccurrenceModel;
  public tempSelectedAppt: MeetingOccurrenceModel;
  public isSubmitted = false;

  filterIcon: string;
  closeDrawerIcon: string;
  siteAttributes: SiteAttributeModel[] = [];

  isClientModule() {
    return this.router.url.includes('clients');
  }

  constructor(
    public inquiriesService: InquiriesService,
    private clientService: ClientService,
    private router: Router,
    private globals: Globals,
    private modalSvc: ModalService,
    private cd: ChangeDetectorRef,
    private apptSvc: AppointmentsDataService,
    private schedDataSvc: SchedulingDataService,
    private translocoService: TranslocoService
  ) {
    super();

    // reroute back home if we don't have a record key for the inquiry
    if (this.isClientModule()) {
      if (!this.apptSvc.case) {
        this.router.navigate(['clients', 'appointments']);
        return;
      }
    } else if (
      this.inquiriesService.parentInquiryKey === undefined ||
      this.inquiriesService.parentInquiryKey === null ||
      this.inquiriesService.parentInquiryKey <= 0
    ) {
      this.globals.activeModule = 'inquiries';
      this.router.navigate(['/inquiries']);

      return;
    }

    // get service name
    this.serviceTitle = this.globals.serviceTitle;

    this.filterIcon = filterIcon;
    this.closeDrawerIcon = closeDrawerIcon;

    // only allowed to book up to 3 months into the future
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);

    // set data for employee header
    if (this.isClientModule()) {
      this.pageTitle = `${this.apptSvc?.case?.firstName} ${this.apptSvc?.case?.lastName}`;
    } else {
      this.pageTitle = this.inquiriesService.inquiry.displayName;
    }
  }

  public ngOnInit(): void {
    this.addSubscriptions([
      // listen for meeting events
      this.schedDataSvc.meetingsReady$.subscribe((data) => {
        if (data !== null) this.showTimeslots();
      }),
      
      // listen for available-date events
      this.schedDataSvc.meetingDatesReady$.subscribe((data) => {
        if (data !== null) {
          this.availableAppointmentDates = this.schedDataSvc.meetingDates;
          if(this.calendar){
            this.calendar.monthView._init();
          }
        }
      }),

      // listen for meeting locked events
      this.schedDataSvc.meetingLocked$.subscribe((data) => {
        if (data !== null) this.displayLockedMeeting();
      }),
    ]);

    // setup form
    this.form = this.createFg();

    // if we have a site - load available appointments
    if (this.isClientModule()) {
      this.parentSiteKey = this.apptSvc.case?.parentSiteKey;
    } else if (this.inquiriesService.inquiry?.parentSiteKey) {
      // site
      this.parentSiteKey = this.inquiriesService.inquiry.parentSiteKey;
    }

    if (!this.parentSiteKey) {
      this.ready = true;
      return;
    }

    this.site = this.inquiriesService.getSite(this.parentSiteKey);

    // Site Designations or Site Attributes
    this.inquiriesService.getSiteAttributes(this.parentSiteKey).subscribe(
      (siteAttributes) => {
        this.siteAttributes = siteAttributes ? siteAttributes : [];
      },
      (err) => {
        console.warn('Site designations error');
      }
    );

    // load next 3 appointments
    this.schedDataSvc.loadLimitedSiteAppointments(
      this.parentSiteKey,
      this.minDate,
      this.maxDate,
      this.appointmentType
    );

    // load dates with availability within calendar range
    this.schedDataSvc.loadSiteAppointmentDates(
      this.parentSiteKey,
      this.minDate,
      this.maxDate,
      this.appointmentType
    );
  }

  public ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();

    // unlock inquiry
    if (!this.unlockRecord) return;
    this.inquiriesService.unlockInquiry();

    // unlock appointment
    if (
      this.selectedAppt !== undefined &&
      this.selectedAppt !== null &&
      this.selectedAppt.parentMeetingOccurrenceKey !== undefined &&
      this.selectedAppt.parentMeetingOccurrenceKey !== null
    ) {
      this.schedDataSvc.unlockAppointment(this.selectedAppt.parentMeetingOccurrenceKey);
    }
  }

  private createFg(): UntypedFormGroup {
    const fcBuild = (val: string | Date, ...validators): UntypedFormControl => new UntypedFormControl(val, validators);

    const appointmentType = fcBuild(this.appointmentType);

    return new UntypedFormGroup({
      appointmentType,
    });
  }

  // filter events
  async siteChange(site: SiteModel): Promise<void> {
    if (this.isSubmitted) return;

    this.ready = false;
    this.loadingTimeslots = true;

    // unlock any previously locked appointments
    if (this.schedDataSvc.lockedMeetingOccurrenceKey !== undefined) {
      this.schedDataSvc.unlockAppointment(this.schedDataSvc.lockedMeetingOccurrenceKey);
    }

    // get appointments for selected site
    this.site = site;
    this.parentSiteKey = site.parentSiteKey;

    // Site Designations or Site Attributes
    this.inquiriesService.getSiteAttributes(this.parentSiteKey).subscribe(
      (siteAttributes) => {
        this.siteAttributes = siteAttributes ? siteAttributes : [];
      },
      (err) => {
        console.warn('Site designations error');
      }
    );

    // Get first 3 appointments for selected site
    this.selectedDate = undefined;
    this.reloadAppointments();
  }

  private reloadAppointments() {
    this.loadingTimeslots = true;

    if (this.selectedDate !== undefined) {
      // If a date is selected, show timeslots for that date.
      this.schedDataSvc.loadSiteAppointments(
        this.parentSiteKey,
        this.selectedDate,
        this.selectedDate,
        this.appointmentType
      );
    }
    else {
      // If no date is selected, show next 3 available timeslots.
      this.schedDataSvc.loadLimitedSiteAppointments(
        this.parentSiteKey,
        this.minDate,
        this.maxDate,
        this.appointmentType
      );
    }
  };

  // select a date and update appointments
  public onSelect(event): void {
    if (this.isSubmitted) return;

    this.loadingTimeslots = true;

    // unlock any previously locked appointments
    if (this.schedDataSvc.lockedMeetingOccurrenceKey !== undefined) {
      this.schedDataSvc.unlockAppointment(this.schedDataSvc.lockedMeetingOccurrenceKey);
    }

    this.selectedDate = event;
    if (this.selectedDate === undefined) this.selectedDate = this.minDate;
    this.selectedDate.setHours(12, 0, 0);

    this.reloadAppointments();
  }

  // Display timeslots from service response
  private showTimeslots(): void {
    this.availableAppointments = this.schedDataSvc.meetings;

    // Reset selection & loading i
    this.selectedAppt = undefined as MeetingOccurrenceModel;
    this.loadingTimeslots = false;
    this.ready = true;
  }

  // select an appointment
  public selectedAppointment(opt: MeetingOccurrenceModel): void {
    this.tempSelectedAppt = opt;
    this.schedDataSvc.lockAppointment(opt.parentMeetingOccurrenceKey);
  }

  // display the locked meeting and allow the user to finalize the selection
  // [or refresh the list if the meeting is now unavailable]
  public displayLockedMeeting(): void {
    if (
      this.schedDataSvc.lockedMeetingOccurrenceKey !== undefined &&
      this.schedDataSvc.lockedMeetingOccurrenceKey !== null
    ) {
      // appointment is locked and ready to finalize selection
      this.selectedAppt = this.tempSelectedAppt;
      this.tempSelectedAppt = undefined as MeetingOccurrenceModel;
    } else {
      // not locked - refresh the list of appointments
      this.tempSelectedAppt = undefined as MeetingOccurrenceModel;
      this.loadingTimeslots = true;

      this.reloadAppointments();
    }
  }

  // save intake meeting booking
  async submitIntakeMeeting() {
    if (this.isSubmitted) return;

    if (this.isClientModule()) {
      this.submitClientIntakeMeeting();
      return;
    }
  
    // check if there is a existing client in the db
    this.clientService.searchForClients(<any>{
      firstName: this.inquiriesService.inquiry.firstName,
      lastName: this.inquiriesService.inquiry.lastName,
      email: this.inquiriesService.inquiry?.inquiryContacts?.filter(
        (contact) => contact.primaryEmailIndicator
      )[0]?.inquiryContact1,
    }).subscribe(async (clients) => {
      clients = clients.map((clientData) => {
        return {
          ...clientData,
        };
      });

      if (clients.length <= 0) {
        this.submitInquiryIntakeMeeting();
        return;
      }

      // if only one client with the same name and email exits then create client using the existing client record
      if (clients.length == 1) {
        if (clients[0].status != 'ACT' && clients[0].status != 'MON') {
          // then use the existing client: clients[0];
          this.submitInquiryIntakeMeetingUsingExistingClient(clients[0].clientKey);
        } else {
          this.ready = true;
          // show the popup saying you cannot create a new client case if there is existing EO-active case
          const dialogResult: DialogResultOptions = await this.modalSvc
            .openCustomDialog(ClientListPopupComponent, {
              data: {
                module: 'INQUIRY',
                clients,
                message: cannotCreateClientCaseForExistingEOActive,
                readonly: true,
              },
            })
            .afterClosed()
            .toPromise();

          // create new client case if continue is clicked
          if (dialogResult.action === 'createNewClient') {
            this.submitInquiryIntakeMeeting();
          } else {
            this.ready = true;
          }
        }
        // if more than one matching client is found
      } else {
        this.ready = true;
        const dialogResult = await this.modalSvc
          .openCustomDialog(ClientListPopupComponent, {
            data: {
              module: 'INQUIRY',
              clients,
              message: checkDuplicateClientListPopupMessageOnInquiry,
              readonly: false,
            },
          })
          .afterClosed()
          .toPromise();

        switch (dialogResult?.action) {
          case 'useExistingClient':
            dialogResult?.client?.parentClientKey
              ? this.submitInquiryIntakeMeetingUsingExistingClient(dialogResult?.client?.parentClientKey)
              : null;
            break;
          case 'createNewClient':
            this.submitInquiryIntakeMeeting();
            break;
          case 'cancel':
            // do nothing
            break;
        }
      }
    });
  }

  submitClientIntakeMeeting() {
    this.ready = false;
    this.apptSvc
      .scheduleClientIntakeMeeting(this.apptSvc.case.parentClientKey, this.selectedAppt.parentMeetingOccurrenceKey)
      .subscribe(
        (result) => {
          this.processSubmitIntakeMeetingResponse(result);
        },
        (err) => {
          console.log(err);
          this.ready = true;
          this.modalSvc.openDialog({ data: this.modalSvc.errorDialog });
        }
      );
  }

  public async submitInquiryIntakeMeeting(): Promise<void> {
    const parentInquiryKey = this.inquiriesService.parentInquiryKey;
    const parentMeetingOccurrenceKey = this.selectedAppt.parentMeetingOccurrenceKey;

    try {
      this.ready = false;
      this.schedDataSvc.saveIntakeMeeting(parentMeetingOccurrenceKey, parentInquiryKey).subscribe(
        (result) => {
          this.processSubmitIntakeMeetingResponse(result);
        },
        (err) => {
          this.modalSvc.openDialog({ data: this.modalSvc.errorDialog });
        }
      );
    } catch (err) {
      this.ready = true;
      console.error(err);
    }
  }

  submitInquiryIntakeMeetingUsingExistingClient(parentClientKey) {
    const parentInquiryKey = this.inquiriesService.parentInquiryKey;
    const parentMeetingOccurrenceKey = this.selectedAppt.parentMeetingOccurrenceKey;

    try {
      this.ready = false;
      this.schedDataSvc
        .saveIntakeMeetingUsingExistingClient({ parentMeetingOccurrenceKey, parentInquiryKey, parentClientKey })
        .subscribe(
          (result) => {
            this.processSubmitIntakeMeetingResponse(result);
          },
          (err) => {
            this.modalSvc.openDialog({ data: this.modalSvc.errorDialog });
          }
        );
    } catch (err) {
      this.ready = true;
      console.error(err);
    }
  }

  processSubmitIntakeMeetingResponse(result) {
    this.ready = true;

    if (result === ProcessResponse.Success || result.client) {
      this.isSubmitted = true;
      return;
    }

    if (result === ProcessResponse.FailedMeetingNoLongerAvailable) {
      const dialog = ERROR_DIALOG;
      dialog.title = '';
      dialog.message = 'This meeting has been booked for another client. Please select a different timeslot.';
      const ref = this.modalSvc.openDialog({ data: dialog });

      typeof ref === 'string' ? undefined : ref.afterClosed().subscribe(() => this.reloadAppointments());
    }
  }

  public async cancel(): Promise<void> {
    // unlock appointment!
    await this.schedDataSvc.unlockAppointment(this.selectedAppt.parentMeetingOccurrenceKey);
    this.selectedAppt = null;
    this.cd.markForCheck();
  }

  public close(): void {
    this.router.navigate(this.isClientModule() ? ['clients', 'appointments'] : [`/inquiries/`]);
  }

  public navigateToDetail(): void {
    this.unlockRecord = false;
    this.router.navigate(this.isClientModule() ? ['clients', 'appointments'] : [`/inquiries/detail`]);
  }

  public dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (this.availableAppointmentDates === undefined) return '';

      const dateAvailable = this.availableAppointmentDates
        .map((appt) => new Date(appt)) // wrap with new Date if not date object
        .some(
          (d) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        );

      if (!dateAvailable) {
        return 'date-unavailable';
      } else {
        return '';
      }
    };
  }
}
